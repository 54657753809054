import "./Faq.css";
import FaqText from "./FaqText";
import GoHomeTimer from "./GoHomeTimer";
import Header from "./Header";

const Faq = () => {
  return (
    <>
      <GoHomeTimer />
      <Header />
      <h1 className="faq-page-title">FAQ</h1>
      <div className="pod-faq-container">
        <div className="pod-faq-scroll">
          <div className="pod-faq-text">
            <FaqText />
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
